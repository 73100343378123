.delete_dialog {
  padding: 3%;
  width: 80vw;
  max-width: 560pt !important;
  max-height: 360pt !important;
  height: 80vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.delete_dialog_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  border-radius: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.delete-button {
  font-weight: normal;
  font-size: 14pt;
  min-height: 0 !important;
  padding: 6pt 12pt;
}

.training-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cancel-button {
  background-color: gray;
  border-color: gray;
  font-weight: normal;
}
.cancel-button:hover,
.cancel-button:focus,
.cancel-button:active {
  background-color: gray !important;
  border-color: gray !important;
}

.delete-text {
  font-size: 1.5rem;
  color: rgb(105, 105, 105);
  font-weight: bold;
  margin-bottom: 1rem;
}
