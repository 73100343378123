.subscription_package {
  display: flex;
  width: 23%;
  min-width: 300px;
  margin: 0 1% 3% 1%;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
}

.subscription_packages_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.subscription_package_description {
  font-weight: bolder;
}

@media (max-width: 640px) {
  .subscription_package {
    width: 90%;
    margin: 0 0 3% 5%;
  }
}
