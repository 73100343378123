.club_image {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  object-fit: contain;
  background-position: center center;
}

.club_image_container {
  position: relative;
  width: 90%;
  height: 0;
  padding: 90% 0 0;
  border-radius: 1000px;
  background-color: white;
  margin: 5% 5% 5% 5%;
  overflow: hidden;
}

.club_text_container {
  text-align: left;
  margin-top: 3vh;
}

.club_name {
  line-height: 1.2em;
  font-weight: bold;
}

.address_container > * {
  margin-bottom: 0;
}

.subscription_container {
  margin-top: 2vh;
}

.address_container_edit {
  margin-top: 3vh;
}

.container {
  padding-top: 2vh;
}

.edit_button {
  margin-top: 3vh;
  margin-right: 2vw;
  text-align: left;
}

.edit_button {
  padding: 4pt 10pt;
}

.edit_button svg {
  margin-right: 8pt;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  height: fit-content;
}

.form_button {
  margin-top: 2vh;
  max-width: 240pt;
}

.label_container {
  text-align: left;
}

label {
  font-weight: bold;
}

.input_row {
  margin-bottom: 0.2%;
  padding-bottom: 0;
}

.squad_name {
  margin-bottom: 0;
  text-align: left;
}

.squad_table_title {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0;
}

.squad_table_item {
  vertical-align: middle !important;
}

.table_container {
  margin-top: 5vh;
}

.squad_table_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.club_image_edit_container {
  position: absolute;
  width: 80%;
  height: 0;
  padding: 80% 0 0;
  margin: 5% 5% 5% 5%;
  overflow: hidden;
}

.club_image_edit_container .club_image_edit {
  width: 15%;
  min-height: 0 !important;
  height: 15%;
  border-radius: 50% !important;
  border-color: lightgray;
  background-color: white;
  position: absolute;
  bottom: 10pt;
  right: 10pt;
  z-index: 45;
  padding: 0;
}

.club_image_edit_icon {
  width: 100%;
  height: 100%;
}

.row {
  flex-wrap: nowrap !important;
}

@media (max-width: 640px) {
  .row {
    flex-wrap: wrap !important;
  }

  .club_name,
  .club_expiration_text {
    text-align: center;
  }

  .club_text_container {
    margin-top: 0;
  }

  .button_container {
    width: 100%;
    justify-content: flex-start;
  }

  .club_text_container .form-label {
    text-align: center;
    width: 100%;
  }

  .squad_member_table_row {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}

.color_showcase {
  border-radius: 5pt;
  margin-left: 5%;
  width: 90%;
  height: 50pt;
}

.chrome-picker {
  width: 50% !important;
  height: 90% !important;
  margin-left: 25%;
  margin-top: 5%;
}

.color_insight {
  margin-top: 5%;
  width: 90%;
  margin-left: 5%;
  padding: 5%;
  border-radius: 10pt;
  position: relative;
}

.insight_sidebar {
  position: absolute;
  top: 0;
  left: -5pt;
  border-radius: 5pt;
  width: 30pt;
  height: 100%;
}

.insight_sidebar_item {
  width: 16pt;
  height: 16pt;
  margin-top: 10pt;
  margin-left: 7pt;
  border-radius: 2pt;
}

.insight_content {
  width: calc(100% - 30pt);
  margin-left: 30pt;
}

.large_text_insight {
  height: 7pt;
  width: 80%;
  margin-left: 15%;
  border-radius: 2pt;
}

.small_text_insight {
  height: 5pt;
  width: 50%;
  margin-left: 45%;
  margin-top: 5pt;
  border-radius: 2pt;
}

.insight_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 25pt;
}

.insight_button {
  height: 20pt;
  width: 30%;
  border-radius: 5pt;
}

.insight_button_text {
  width: 60%;
  margin-left: 20%;
  margin-top: 8pt;
  height: 4pt;
  border-radius: 2pt;
}

.insight_table {
  margin-top: 25pt;
}

.insight_table_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5pt;
}

.insight_table_header {
  width: 28%;
  height: 5pt;
  border-radius: 2pt;
}

.insight_table_item {
  width: 23%;
  height: 3pt;
  border-radius: 2pt;
}

.popup_form {
  overflow-y: auto;
}

.color_title {
  margin-bottom: 0;
}

.club_management_title {
  font-weight: bold;
  margin-bottom: 0;
}

.club_list_table_item {
  margin-bottom: 0;
  text-align: left;
}

.club_list_table_header {
  margin-bottom: 0;
  text-align: left;
  font-weight: bolder;
}
.club_list_table_header.no_wrap {
  white-space: nowrap;
}

.club_list_table_header_container {
  border: none !important;
}

.logo_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-position: center center;
}

.logo_image_container {
  vertical-align: middle;
  position: relative;
  width: 32pt;
  height: 0;
  padding: 32pt 0 0 !important;
  border-radius: 50%;
  overflow: hidden;
  margin: 5%;
}

.club_table_title_container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 2vh;
}

.club_table_title {
  text-align: left;
  margin: 0 2% 0 2%;
}

.table td {
  padding: 0 0.5rem;
  background-color: transparent;
}

.club_expiration_text {
  font-weight: bold;
}

.button.table-item {
  margin: 5pt 5px;
}

.club_category_list_table_item {
  margin-bottom: 0;
  text-align: left;
  font-weight: bold;
}

.squad_member_table_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5pt;
  flex-wrap: nowrap;
}

.squad_member_name_photo_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.tooltip, .tooltip-inner {
  max-width: min(100%, 500pt);
}