body {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.sidebar {
  height: 100vh;
  width: 200pt;
  position: fixed;
  top: 0;
  left: -6pt;
  padding: 10pt 32pt 10pt 8pt;
  transition: all 0.3s ease;
  z-index: 50;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar.active {
  width: 200pt;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .logo_content {
  margin: 0 auto;
  width: 100%;
}

.sidebar .logo_content.club_logo_content {
  width: 190pt;
}

.sidebar .logo_content .logo {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.sidebar .logo_content .logo.club_logo {
  width: 120pt;
  height: 120pt;
  border-radius: 1000px;
  overflow: hidden;
  padding: 12pt;
  background-color: white;
}

.sidebar .logo_content .logo > img {
  width: 70%;
  height: 100%;
  object-fit: contain;
}

.sidebar .logo_content.club_logo_content .logo > img {
  width: 100%;
  height: 100%;
}

.sidebar .logo_content .logo {
  opacity: 1;
}

.sidebar-mobile-header #btn {
  position: absolute;
  right: 50%;
  top: 10pt;
  font-size: 20pt;
  text-align: center;
  line-height: 24pt;
  transform: translateX(50%);
  cursor: pointer;
  padding: 8pt;
  border-radius: 6pt;
}

.sidebar ul {
  margin-top: -10pt;
  margin-left: 0;
  padding: 0 0 10pt 0;
}

.sidebar ul li {
  position: relative;
  list-style: none;
  line-height: 32pt;
  width: calc(100% + 24pt);
  /* margin: 12pt 4pt; */
  margin-left: -2pt;
  margin-top: 12pt;
  text-align: left;
  border-top-right-radius: 45pt;
  border-bottom-right-radius: 45pt;
}

.sidebar ul li:hover, .sidebar ul li:hover a svg {
  font-weight: bold;
  opacity: 1 !important;
}

.sidebar .logout_container:hover{
  color: var(--primary-color);
  background-color: white;
}

.sidebar ul li a:hover {
  font-weight: bold;
}

.sidebar .club_select {
  overflow: hidden;
  font-size: 15pt;
  font-weight: bolder;
  text-align: left;
  padding: 8pt 0 8pt 0pt;
  width: calc(100% + 24pt);
}

.custom-select {
  border-radius: 45pt;
  text-align-last: center;
  -moz-text-align-last: center;
  -ms-text-align-last: center;

  width: 90%;
  margin-left: 5%;
}

.sidebar .bars-icon {
  display: none;
}

.sidebar ul li a {
  font-size: 15pt;
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 6px;
  white-space: nowrap;
  font-weight: bolder;
  margin-bottom: -5pt;
}

.sidebar .links_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar ul li a svg {
  border-radius: 6px;
  line-height: 32pt;
  padding: 8pt;
  margin-right: 4pt;
  margin-top: -3pt;
  opacity: 0.6;
}

.sidebar .logout_container {
  cursor: pointer;
  margin-top: 12pt;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 100px;
  width: 160pt;
  height: 32pt;
  font-size: 15pt;
  margin-left: 12pt;
  color: white;
  padding-left: 4pt;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.sidebar .logout_container svg {
  height: 32pt;
  min-width: 32pt;
  border-radius: 6px;
  line-height: 32pt;
  padding: 8pt;
  margin-right: 4pt;
}

.sidebar .logout_text {
  font-weight: bolder;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar.active .logout_text {
  opacity: 1;
}

.sidebar-mobile-header {
  display: none;
}

.club-logo-wrapper {
  position: absolute;
  top: 4pt;
  left: 14pt;
  width: 28pt;
  height: 28pt;
  object-fit: contain;
  border-radius: 100px;
  overflow: hidden;
  background-color: white;
}

.club-logo {
  position: absolute;
  top: 2pt;
  left: 2pt;
  width: 24pt;
  height: 24pt;
  object-fit: contain;
}

.club-logo.logo-hp {
  position: absolute;
  top: 4pt;
  left: 14pt;
  width: 56pt;
  height: 28pt;
  object-fit: contain;
  border-radius: 100px;
}

.shortcut-buttons-container {
  width: 140pt;
  height: 32pt;
  margin-left: 22pt;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.shortcut-buttons-container .shortcut-button {
  width: 32pt;
  height: 32pt;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 20pt;
  opacity: 0.6;
}

.shortcut-buttons-container .shortcut-button:hover {
  background-color: white;
  color: var(--primary-color);
  opacity: 1;
}

.sidebar .sub-menu{
  width: 180pt;
}

.sidebar .sub-menu ul li:first-child{
  margin-top: 8pt;
}

.sidebar .sub-menu .sub-sub-menu ul li:first-child{
  margin-top: 0;
}

.sidebar .sub-menu ul li{
  margin-left: 16pt;
  margin-top: -10pt;
  color: white;
  font-weight: bolder;
  list-style: inside;
  font-size: 13pt;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 16pt);
}

.sidebar .sub-menu .sub-sub-menu ul li{
  margin-left: 24pt;
  width: calc(100% - 24pt);
  font-size: 11pt;
}

.sidebar .sub-menu ul li span{
  text-overflow: ellipsis;
}

.sidebar .sub-menu ul li:hover, .sidebar .sub-menu ul li span.active-submenu-item {
  font-weight: bold;
}

.sidebar .sub-menu ul {
  padding-bottom: 0;
}

.chevron-icon {
  transform: scaleX(.8);
  transition: all 0.2s ease;
}

.sidebar .chevron-icon {
  position: absolute;
  right: 0;
  color: white;
}

.active-menu-item .chevron-icon {
  transform: rotate(180deg) scaleX(.8);
}

@media (max-width: 640px) {

  .sidebar-mobile-header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 36pt;
    z-index: 51;
  }

  .sidebar .tooltip {
    display: none;
  }

  .sidebar {
    position: fixed;
    width: 0;
    padding: 0;
    left: calc(100% + 250pt);
    overflow: visible;
  }

  .sidebar .sidebar-bottom-container {
    width: 0 !important;
    display: none !important;
  }

  .sidebar.active .sidebar-bottom-container {
    width: 190pt !important;
    display: block !important;
  }

  .sidebar .bars-icon {
    display: block;
  }

  .sidebar ul {
    margin-left: -7pt;
  }

  .sidebar ul hr {
    opacity: 0;
  }

  .sidebar ul li a svg {
    opacity: 0;
    margin-left: 5pt;
  }

  .sidebar.active ul hr {
    opacity: 1;
  }

  .sidebar.active ul li a svg {
    opacity: 0.7;
  }

  .sidebar .logout_container svg {
    opacity: 0;
  }

  .sidebar.active .logout_container svg {
    opacity: 1;
    margin-left: 5pt;
  }

  .sidebar-mobile-header #btn {
    right: 18pt;
    top: 0;
    transition: all 0.3s ease;
  }

  .sidebar.active {
    left: calc(100% - 200pt);
    padding: 36pt 32pt 10pt 8pt;
  }

  .sidebar.active .sub-sub-menu ul li {
    margin-left: 32pt;
    width: calc(100% - 32pt);
  }

}

.active-menu-item a span,
.active-menu-item a svg {
  font-weight: bold;
  opacity: 1 !important;
}

.language_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10pt;
  width: 100%;
}

.language {
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.language:hover {
  text-decoration: underline;
}

.language-selected {
  text-decoration: underline;
}

.language-separator {
  margin-left: 5pt;
  margin-right: 5pt;
  font-size: 15pt;
  font-weight: bold;
  color: white;
}

.sidebar-content-wrapper {
  position: relative;
  min-height: calc(100% - 20pt);
  width: 100%;
  overflow: visible;
}

.sidebar-bottom-container {
  position: absolute;
  bottom: 0;
  width: 190pt;
}

.sidebar .contact-container {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact-text {
  color: white;
  font-size: 0.8em;
}

#mail {
  font-size: 0.9em;
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.sidebar .nav_list {
  padding-bottom: 200pt;
}

li.li-plus {
  list-style: none !important;
}

li.li-plus::before {
  content: "+";
  color: white;
  font-size: 1em;
  margin-right: 4pt;
}