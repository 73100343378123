.category_title {
  text-align: left;
  user-select: none;
  margin-bottom: 0;
}

.category_title.category_score {
  font-size: 1.5rem;
}

.table-item {
  margin: 5pt 0;
  font-weight: normal;
}

.category_container {
  margin-bottom: 3vh;
}

.title {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

.new_resource_edit_container {
  display: inline-block;
  position: relative;
  width: 9vw;
  padding: 9vw 0 0;
  margin: 1% 1% 1% 1%;
  overflow: hidden;
  border-radius: 5pt;
  background-color: #ececec;
}

.test_resource_edit_container {
  display: inline-block;
  position: relative;
  margin: 1% 1% 1% 1%;
  overflow: hidden;
  border-radius: 5pt;
  background-color: #ececec;
}

.test_resource_video_edit_container {
  display: inline-block;
  position: relative;
  width: 12vw;
  height: 9vw;
  margin: 1% 1% 1% 1%;
  overflow: hidden;
  border-radius: 5pt;
  background-color: #ececec;
}

.title-button-container > .add-button {
  width: 100%;
  margin-bottom: 0.2rem !important;
}

@media (max-width: 640px) {
  .title {
    font-size: 1.953rem;
    line-height: 2.25rem;
  }

  .title-button-container {
    width: 100%;
    margin: 0 5%;
  }

  .test_resource_edit_container {
    width: 90%;
    height: 0;
    padding: 90% 0 0;
    margin: 1% 5% 1% 5%;
    overflow: hidden;
  }

  .new_resource_edit_container {
    width: 90%;
    height: 0;
    padding: 90% 0 0;
    margin: 1% 5% 1% 5%;
    overflow: hidden;
  }

  .category_container.test_container.row {
    margin-left: 0;
    margin-right: 0;
  }

  .test-result-detail-title {
    font-size: 2rem;
    padding-right: 32pt;
    text-align: left;
  }

  .btn-group button.left-button,
  .btn-group button.right-button,
  .btn-group button.middle-button {
    border-radius: 1000px !important;
  }
}

.test_detail_graph_container {
  height: 58vh;
  margin-top: 5vh;
  overflow-y: hidden;
  overflow-x: visible;
}

.test_detail_list_container {
  height: 60vh;
  margin-top: 5vh;
  overflow: auto;
}

.test_row {
  cursor: pointer;
}

.test-results-container {
  overflow-x: auto;
  width: 100%;
}

.test-results-container .row {
  margin-left: 0;
  margin-right: 0;
}

.test_results_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.test-result-detail-title {
  font-weight: bold;
  margin-bottom: 1vh;
  hyphens: auto;
}

.btn-group .button {
  width: 7rem;
  font-weight: bold;
  min-height: 0 !important;
}

.btn-group .wider-button {
  width: 12rem;
}

.btn-group .left-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 100pt !important;
  border-top-left-radius: 100pt !important;
}

.btn-group .right-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 100pt !important;
  border-top-right-radius: 100pt !important;
}

.btn-group .middle-button {
  border-radius: 0 !important;
}

.assigned_exercises_title {
  font-weight: bold;
  margin-bottom: 0;
}

.input-with-unit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
