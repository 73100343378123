@font-face {
  font-family: "Clio";
  src: url("Fonts/LeType - ClioRegular.otf") format("opentype");
}

.App {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Clio, sans-serif;
  background-color: #f5f5f5;
}

.btn-primary:hover,
.btn-primary:focus {
  -webkit-box-shadow: 2px 2px 5px 2px rgba(230, 230, 230, 0.8) !important;
  box-shadow: 2px 2px 5px 2px rgba(230, 230, 230, 0.8) !important;
}

.form-control:focus {
  border-color: gray !important;
}

.login-a:hover {
  text-decoration: none;
}

.btn {
  -webkit-box-shadow: 2px 2px 5px 2px rgba(230, 230, 230, 0.8) !important;
  box-shadow: 2px 2px 5px 2px rgba(230, 230, 230, 0.8) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Clio", sans-serif !important;
}

.main-app-container {
  min-height: 100vh;
  position: relative;
}

/* Scrollbars */
 *::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   border-radius: 10px;
   background-color: #F5F5F5;
 }

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(180, 180, 180, 0.3);
  background-color: var(--primary-color);
}