.squad_management_title {
  text-align: center;
  font-weight: bold;
}

.squad_member_name {
  margin-bottom: 0;
  text-align: left;
}

.squad_member_email {
  margin-bottom: 0;
  text-align: left;
}

#new_member_email_form {
  margin-top: 5vh;
  height: 50vh;
}

#new_member_form {
  margin-top: 5vh;
  height: 50vh;
}

.profile_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
}

.profile_image_container {
  vertical-align: middle;
  position: relative;
  width: 32pt;
  height: 0;
  padding: 32pt 0 0 !important;
  border-radius: 50%;
  overflow: hidden;
}

#squad_detail_buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

#squad_detail_buttons button {
  font-size: 12pt;
  margin-top: 12pt !important;
  padding-left: 18pt !important;
  padding-right: 18pt !important;
  border-radius: 100px !important;
}

@media (max-width: 640px) {
  .squad_member_button {
    font-size: 4vw;
  }

  #squad_detail_buttons {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  #squad_detail_buttons button {
    width: 100%;
  }
}

.inactive_user_text {
  font-size: 80%;
}

.squad_text_container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.squad_text_container h2 {
  font-weight: bold;
}