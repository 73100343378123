.profile_photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
}

.profile_name {
  font-weight: bold;
}

.profile_email,
.profile_sex,
.profile_birth_date,
.address_text {
  color: rgb(105, 105, 105) !important;
}

.address_text {
  margin-bottom: 0;
}

.width-90 {
  width: 90%;
  margin-left: 5%;
}

.cont_width {
  width: calc(100% - 194pt);
  margin-left: 194pt;
  padding-left: 20pt;
}

.profile_photo_container {
  position: relative;
  width: 80%;
  height: 0;
  padding: 80% 0 0;
  border-radius: 50%;
  margin: 5% 5% 5% 5%;
  overflow: hidden;
}

.image_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 560px;
}

.profile_club_logo_container {
  position: absolute;
  width: 80%;
  height: 0;
  padding: 80% 0 0;
  margin: 5vh 5% 5% 5%;
  overflow: hidden;
}

.profile_data_container {
  display: flex;
  align-items: flex-start;
}

.profile_text_container {
  margin-top: 3vh;
}

.profile_name,
.profile_email,
.profile_sex,
.profile_birth_date {
  line-height: 1em;
}

.profile_sex {
  text-transform: capitalize;
}

label {
  margin-bottom: 0 !important;
}

.address_container {
  margin-top: 3vh;
}

.profile-data-button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-right: 15pt;
}

.buttons-container.profile-buttons-container {
  margin-top: 3vh !important;
}

.settings-card {
  padding: 0 2rem 3rem 2rem;
}

#member_edit_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

#member_edit_buttons button {
  font-size: 12pt;
  margin-top: 12pt !important;
  padding-left: 18pt !important;
  padding-right: 18pt !important;
  border-radius: 100px !important;
}

@media (max-width: 640px) {
  #member_edit_buttons {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  #member_edit_buttons button {
    width: 100%;
  }

  .profile_name,
  .profile_sex,
  .profile_birth_date,
  .profile_email {
    text-align: center;
  }

  .graph_container {
    min-width: 0 !important;
    width: 90vw !important;
    max-width: 90vw !important;
  }

  .profile_text_container {
    margin-top: 0;
  }

  .cont_width {
    width: 100%;
    margin: 0;
    padding-left: 0;
  }

  .profile_data_container {
    display: block;
    margin-bottom: 12pt;
    margin-top: 32pt;
  }

  .profile_text_container .form-label {
    width: 100%;
    text-align: center;
  }

  .address_text {
    text-align: center;
  }

  .profile-data-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-right: 0;
  }

  .profile-edit-button {
    margin: 0 auto;
  }

  .settings-card {
    padding: 0 0 3rem 0;
  }

  .member_name {
    justify-content: center !important;
  }
}

@media (hover: none) {
  .tooltip {
    display: none;
  }
}

.container {
  padding-top: 2vh;
}

.graph_container {
  position: relative;
  min-width: 500px;
  max-width: 80vw;
}

.graphs {
  padding: 0;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

label {
  font-weight: bold;
}

.input_row {
  margin-bottom: 0.2%;
  padding-bottom: 0;
}

.profile_photo_edit_container {
  position: absolute;
  width: 80%;
  height: 0;
  padding: 80% 0 0;
  margin: 5vh 5% 5% 5%;
  overflow: hidden;
}

.profile_photo_edit {
  width: 15%;
  min-height: 0 !important;
  height: 15%;
  border-radius: 50% !important;
  border-color: lightgray !important;
  background-color: white !important;
  position: absolute;
  bottom: 8%;
  right: 8%;
  z-index: 45;
  padding: 0;
}

.profile_club_logo_image_container {
  width: 35%;
  min-height: 0 !important;
  height: 35%;
  border-radius: 50% !important;
  position: absolute;
  bottom: 3%;
  left: 3%;
  z-index: 45;
  padding: 0;
  object-fit: cover;
  background-position: center center;
  overflow: hidden;
  border-radius: 1000px;
  background-color: white;
}

.profile_club_logo {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  object-fit: cover;
  background-position: center center;
}

.profile_photo_edit_icon {
  width: 30%;
  height: 30%;
  object-fit: contain;
  padding: 35%;
}

.profile_edit_container {
  width: 15%;
  height: 15%;
  border-radius: 50%;
  position: absolute;
  bottom: 8%;
  right: 8%;
  z-index: 45;
  padding: 0;
}

.button-even-wider {
  width: 12rem !important;
}

#address {
  margin-bottom: 14pt;
}

@media (max-width: 1440px) {
  .form_flex_container .row {
    flex-wrap: wrap !important;
  }

  .form_flex_container .col-lg-3,
  .form_flex_container .col-lg-5,
  .form_flex_container .col-lg-7,
  .form_flex_container .col-lg-10,
  .form_flex_container .col-lg-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
  }

}