.shared_resource_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
}

.shared_file_name,
.file_name {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
}

.file_name {
  cursor: pointer;
  margin-bottom: 0.2rem;
  max-width: 85vw;
}

.file_name:hover {
  text-decoration: underline;
}
