@keyframes image_in {
  from {
    transform: translate(-50%, -50%) scale(0.2, 0);
  }
  to {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@keyframes bg_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes image_out {
  from {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  to {
    transform: translate(-50%, -50%) scale(0.2, 0);
  }
}

@keyframes bg_out {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

.enlarged_image {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  /* transition-duration: 500ms; */
  border-radius: 0;
  animation: image_in 300ms ease-out 0s 1;
}

.resource_image.fullscreen {
  margin: 0 !important;
  height: 100% !important;
}

.enlarged_image_background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8) !important;
  left: 0;
  top: 0;
  z-index: 99 !important;
  animation: bg_in 200ms ease-out 0s 1;
}

.enlarged-image-btn-group {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 101;
}

.enlarged-image-button {
  background-color: #777777;
  width: 4rem !important;
  opacity: 0.8;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  border: none;
}

.center-button.enlarged-image-button {
  width: 5rem !important;
  font-size: 1.3rem;
  font-weight: normal;
}

.arrow-button {
  position: absolute;
  z-index: 101;
}

.left-arrow-button {
  left: 10pt;
  top: 50%;
  transform: translateY(-50%);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.right-arrow-button {
  right: 10pt;
  top: 50%;
  transform: translateY(-50%);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
