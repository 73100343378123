.heat_map_row_container {
  height: 50pt;
}

.heat_map_labels_row_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.club_tests_title {
  font-weight: bold;
  margin-bottom: 0;
}

.training-overview-count {
  white-space: nowrap;
  font-weight: bolder;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.training-overview-table td, .training-overview-table th {
  padding: 0.3rem 1rem;
}

.training-overview-table thead th {
  border : none;
  padding-bottom: 1rem;
}