.sale-desc {
  text-align: left;
  font-size: 14pt;
}

.sale-discount-code-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sale-discount-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.sale-code-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.sale-discount,
.sale-promo-code {
  font-weight: bold;
}

.sale_resource_edit_container {
  margin: 10pt auto;
  min-height: 96pt;
}

.resource_image.sale_image {
  width: 90%;
  min-width: 90pt;
  height: auto;
  min-height: 0;
  margin-left: 5%;
}

.sale_image {
  width: 90pt;
  margin-left: 0;
  height: auto;
  min-height: 0;
  border-radius: 5pt;
}

.sale-desc-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 15px;
}
@media (max-width: 640px) {
  .sale-discount-code-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .sale-code-container {
    justify-content: flex-start;
  }

  .sale_resource_edit_container {
    width: 80%;
  }

  .sale-desc-container {
    align-items: center;
  }

  .sale_image {
    max-width: 90%;
  }
}
