.exercise_management_title {
  font-weight: bold;
  margin-bottom: 0;
}

.exercise_card {
  margin-bottom: 1rem;
  box-shadow: none !important;
}

.exercise_resources_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  border-radius: 10px;
  margin-bottom: 5%;
  object-position: top;
}

.resource_image {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  height: 9vw;
  min-height: 90pt;
  border-radius: 5pt;
}

.resource_image.smaller-image {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  height: 8vw;
  min-height: 90pt;
  margin: 5pt;
  max-width: 100%;
  object-fit: contain;
}

.resource_image.bigger-image {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 120pt;
  margin: 5pt;
}

.enlarged_image .resource_image {
  width: 100%;
  height: 100%;
}

.resource_video_container {
  display: inline-block;
  position: relative;
  width: 12vw;
  height: 9vw;
  margin: 5pt;
  border-radius: 5pt;
}

.resource_video_container.bigger-video {
  min-height: 120pt;
  height: 100%;
  min-width: 120pt;
}

.resource_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 5pt;
}

.resource_video:fullscreen {
  object-fit: contain;
}

.resource_container {
  display: inline-block;
  position: relative;
  width: 12vw;
  height: 9vw;
  margin: 1% 1% 1% 1%;
  overflow: hidden;
  border-radius: 5pt;
  background-color: #ececec;
}

.resource_container_with_description {
  display: inline-block;
  position: relative;
  width: 12vw;
  height: calc(9vw + 50pt);
  margin: 1% 1% 1% 1%;
  overflow: hidden;
  border-radius: 5pt;
  background-color: #ececec;
}

.resource_description_container {
  position: absolute;
  width: 90%;
  height: 50pt;
  overflow: auto;
  top: 9vw;
  left: 5%;
  font-size: 12pt;
}

.resource_edit_container {
  display: inline-block;
  position: relative;
  margin: 1% 1% 1% 1%;
  overflow: hidden;
  border-radius: 5pt;
  background-color: #ececec;
}

.progress_bar {
  width: 50%;
  height: 0;
  padding-bottom: 50%;
  left: 50%;
  top: 50%;
}

.progress_bar_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.progress_bar_text {
  width: 100%;
  font-size: 3vw;
  line-height: 3vw;
}

.progressText {
  font-size: 8vw;
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.card-container {
  padding-left: 14pt;
  padding-right: 14pt;
  padding-bottom: 24pt;
}

.row > .card-container {
  padding-left: 24pt;
  padding-right: 24pt;
}

.exercise_resource_video_container {
  display: inline-block;
  position: relative;
  width: 10.66vw;
  height: 8vw;
  min-height: 90pt;
  min-width: 120pt;
  margin: 5pt;
}

@media (max-width: 640px) {

  .card-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-container .card {
    box-shadow: none !important;
  }

  .exercise_management_title {
    font-size: 8vw;
    line-height: 9vw;
  }

  .member_title {
    font-weight: bold;
    font-size: 7vw;
    line-height: 8vw;
  }

  .resource_container {
    width: 90%;
    padding: 90% 0 0;
    margin: 1% 0 1% 0;
    overflow: hidden;
  }

  .resource_image {
    width: calc(100% - 10pt);
    height: auto !important;
    max-height: 100vh !important;
    min-height: 0 !important;
  }

  .resource_image.input_image {
    position: absolute !important;
  }

  .resource_description_container {
    height: 25%;
    top: 75%;
  }

  .resource_container_with_description {
    width: 90%;
    padding: 120% 0 0;
    margin: 1% 0 1% 0;
    overflow: hidden;
  }

  .resource_container .not_full_screen_resource {
    max-height: 100%;
  }

  .resource_container_with_description .not_full_screen_resource {
    max-height: 75%;
  }

  .resource_video {
    object-fit: cover;
  }

  .resource_edit_container {
    width: 90%;
    height: 0;
    padding: 90% 0 0;
    margin: 1% 5% 1% 5%;
    overflow: hidden;
  }

  .progress_bar {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    left: 50%;
    top: 50%;
  }

  .progressText {
    font-size: 15vw;
  }

  .progress_bar_text {
    font-size: 7vw;
    line-height: 7vw;
  }

  .exercise_collapse_content {
    flex-direction: column-reverse !important;
  }

  .resource_video_container {
    display: inline-block;
    position: relative;
    width: 60vw;
    height: 45vw;
    margin: 5pt;
    border-radius: 5pt;
  }

  .not_full_screen_resource {
    max-height: 100vw;
  }

  .exercise_resource_video_container {
    width: 90%;
    margin-left: 5% !important;
    height: 51vw;
  }
}

.input_text_area_row {
  height: 30%;
}

.drag_and_drop {
  text-align: left;
  border: 2px solid gainsboro;
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;
  margin-bottom: 5pt;
}

.file_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5%;
}

.exercise_form_container {
  height: 50vh;
  overflow-y: auto;
}

.exercise_title {
  text-align: left;
  font-weight: bold;
  user-select: none;
  margin-bottom: 0;
}

.global_exercise_text {
  font-size: 80%;
  text-align: left;
  margin-top: 0;
}

.exercise_resource_name {
  text-align: left;
}

.empty_file_select_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.empty_list_text {
  margin: 2vw 0 4vw 0;
}

.resource_button_remove_container {
  position: absolute;
  top: -3pt;
  right: -3pt;
  z-index: 10;
  width: 25pt;
  height: 25pt;
}

.resource_button_notes_container {
  position: absolute;
  bottom: -3pt;
  right: -3pt;
  z-index: 10;
  width: 25pt;
  height: 25pt;
}

.resource_text_area_container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  overflow: hidden;
}

.resource_text_area {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.text_area_hide_button_container {
  position: absolute;
  top: -5pt;
  right: -5pt;
  z-index: 10;
  width: 20pt;
  height: 20pt;
}

.resource_button {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.resource_button > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.not_empty_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.add_new_resource_icon {
  position: absolute;
  left: 30%;
  top: 10%;
  width: 40% !important;
  height: 40%;
}

.add_new_resource_text {
  position: absolute;
  text-align: center;
  left: 10%;
  top: 50%;
  width: 80% !important;
  height: 40%;
}

.close_dialog_button {
  position: absolute;
  width: 32pt !important;
  height: 32pt !important;
  font-size: 32pt !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  z-index: 15;
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.close_dialog_button:hover,
.close_dialog_button:focus,
.close_dialog_button:active {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.current_exercises_title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
}

.exercise_collapse_content {
  display: flex;
  flex-direction: row;
}

.exercise-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.exercise_resources_container > * {
  margin: 5pt;
}

.custom-control {
  display: inline-block;
}

.not_full_screen_image {
  max-height: 10vw;
}

.title-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-body.exercise_card_body {
  padding: 0.4rem 1.35rem !important;
}

.info_icon {
  font-size: 65%;
  color: #d5d5d5;
  cursor: pointer;
  margin-left: 1rem;
}

.info_icon_input {
  font-size: 1.3rem;
  color: #d5d5d5;
  cursor: pointer;
  margin-right: 0.5rem;
}