.club-compare-table td,
.club-compare-table th {
  border-right: 1px solid #5a6169;
}

.squad-results-chart {
  height: 100%;
}

@media (max-width: 640px) {
  .buttons-container {
    width: 90% !important;
    margin: 0 5% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .buttons-container .button {
    width: 100% !important;
    border-radius: 0.375rem !important;
    margin-bottom: 0.375rem !important;
    margin-right: 0 !important;
  }
}
