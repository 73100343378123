.rpe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rpe-form-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.rpe-form-container {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
