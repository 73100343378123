.health-record-card-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact-injury {
  font-size: 100%;
  text-transform: uppercase;
  color: #169fa8;
}

.non-contact-injury {
  font-size: 100%;
  text-transform: uppercase;
  color: #3800d1;
}

.figurine_container {
  height: 360pt;
  max-height: 50vh;
  position: relative;
}
.figurine_image,
.figurine_map {
  left: -50%;
  top: 0;
  width: auto;
  height: 100%;
  position: relative;
}

.body_part_image {
  position: absolute !important;
  z-index: 20;
  pointer-events: none;
  transition: 0.3s ease-in-out;
}

.body_part_image_contact {
  position: absolute !important;
  z-index: 10;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  opacity: 1 !important;
  filter: hue-rotate(170deg);
}

.body_part_image_non_contact {
  position: absolute !important;
  z-index: 10;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  opacity: 1 !important;
  filter: hue-rotate(240deg);
}

.hidden {
  display: none !important;
}

.health_record_header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.health_record_title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.health-overview-table td,
.health-overview-table th {
  border-right: 1px solid #5a6169;
  vertical-align: top;
}

@media (max-width: 640px) {
  .col_no_padding_mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .row_no_margin_mobile {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
