.test_management_title {
  font-weight: bold;
  margin-bottom: 0;
}

.align_left {
  text-align: left;
}

.main_container {
  padding-top: 5vh;
  overflow-x: hidden;
  padding-bottom: 72pt;
}

.category_title {
  font-weight: bold !important;
  word-break: normal;
  hyphens: auto;
}

.card-body {
  padding: 1.35rem !important;
}

.button {
  margin: 0 5px 0 5px;
}

.card_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.collapse-content {
  margin-top: 1rem;
}

.card {
  border-radius: 0.75rem;
}

.title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
  flex-wrap: wrap;
}

.popup_form_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 60;
  border-radius: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.35);
}

.popup_form {
  position: fixed;
  width: 80vw;
  height: 80vh;
  left: 50%;
  top: 50%;
  z-index: 70;
  transform: translate(-50%, -50%);
  transition-duration: 0ms;
  overflow: hidden;
}

.height-100 {
  height: 100% !important;
}

.popup_form_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 88%;
  overflow-y: auto;
}

.popup_form_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  min-height: 12%;
}

.popup_form_content_row {
  margin-bottom: 2%;
}

.label_row {
  margin-bottom: -0.5em;
}

.align_bottom {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.button_text {
  margin-bottom: 0;
}

.popup_form_container#progressBar {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1);
}

.test_template_club_table {
  width: 80%;
}

.add-button {
  min-height: 0 !important;
  border-radius: 100rem !important;
  padding: 0.5rem 1.5rem 0.55rem 1.5rem !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}

.save-button {
  font-size: 14pt !important;
  margin-bottom: 1rem !important;
}

.settings-button {
  font-size: 14pt !important;
}

.smaller-button {
  padding: 0.5rem !important;
  min-height: 0 !important;
}

.detail-button {
  padding: 0.5rem 1.5rem !important;
}

.form-label {
  margin-bottom: 0;
  font-weight: 600;
  color: rgb(105, 105, 105);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--primary-color) !important;
}

.plus-button {
  padding: 15pt !important;
  font-size: 125%;
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.plus-button:hover,
.plus-button:focus,
.plus-button:active {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.test_options_title {
  color: rgb(105, 105, 105);
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}

.test_options_title > .btn {
  color: rgb(105, 105, 105);
}

.score-input-label {
  color: rgb(105, 105, 105);
  font-weight: bold;
  text-decoration: center;
  margin-bottom: 0.5rem;
}

.list-card:hover {
  background-color: var(--lighter-primary-color) !important;
}

.exercise_card:hover,
.test_card:hover {
  background-color: white !important;
}

.list-card {
  min-width: 100%;
  width: fit-content;
}

.card-list-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.no-break {
  flex-wrap: nowrap !important;
}

.button-wider {
  width: 9rem !important;
}

.table .table {
  background-color: transparent;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 992px) {
  .main_container {
    width: 90%;
    margin-left: 5%;
    margin-top: 40pt;
    margin-bottom: 0;
  }
}

@media (max-width: 640px) {
  .test_management_title {
    font-size: 8vw;
    line-height: 9vw;
  }

  .button {
    margin: 0 5px 0 0 !important;
  }

  .card-list-header {
    flex-wrap: wrap;
  }

  .card-body {
    padding: 1.35rem 0.2rem !important;
  }

  .no_padding_mobile {
    padding: 0 !important;
  }

  .popup_form_title_container h1 {
    font-size: 2.2em;
  }
}
