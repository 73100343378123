.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 14pt 24pt;
  width: 100%;
  height: 64pt;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.contact-button {
  border-radius: 100px !important;
  transition: all 0.4s ease;
}

.contact-button:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-color: white !important;
}

@media (max-width: 640px) {
    .footer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}