.dashboard-module {
  padding-left: 14pt;
  padding-right: 14pt;
  padding-bottom: 24pt;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-content-container {
  position: relative;
  flex: 1 1 auto;
}

.dashboard_title {
  margin-bottom: 0;
  font-size: 18pt;
}

.dashboard-card {
  border-top-right-radius: 0;
  height: 100%;
  max-height: 640pt;
  overflow-y: hidden;
}

.dashboard-card > .card-body {
  overflow: auto;
  padding: 0 !important;
  margin: 10pt;
}

.dashboard-user-results-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.dashboard-user-results-container .graph_container {
  scroll-snap-align: start;
}

.dashboard-user-results {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 60pt;
  margin: 0 8pt;
  padding: 4pt 0 12pt 0;
  cursor: pointer;
  scroll-snap-align: start;
  position: relative;
}

.dashboard-user-results h5 {
  white-space: nowrap;
}

.dashboard-user-result-photo-container {
  max-width: 60pt;
  width: 100%;
}

.dashboard-user-injury-photo-container {
  min-width: 60pt;
}

.dashboard-select {
  position: absolute;
  height: 24pt !important;
  top: -24pt;
  right: 0;
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid lightgray;
  border-bottom: none;
  background-color: white;
  width: 140pt;
  z-index: 10;
  margin: 0;
  color: var(--primary-color) !important;
  font-weight: bold;
}

.dashboard-select-bottom-border-cover {
  position: absolute;
  height: 3px;
  top: -1px;
  right: 1px;
  border: 0;
  background-color: white;
  width: calc(140pt - 2px);
  z-index: 11;
  margin: 0;
}

.dashboard-select:focus,
.dashboard-select:hover {
  border: 1px solid lightgray !important;
  border-bottom: none !important;
  outline: none;
  box-shadow: none;
}

.dashboard-club-results-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.dashboard-club-compare-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-club-compare-graph {
  flex: 1;
  overflow-x: auto;
  min-height: 400pt;
}

.dashboard-user-injury-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 12pt;
  scroll-snap-align: start;
}

.dashboard-user-injury-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16pt;
  text-align: left;
}

#dashboard_squad_results,
#dashboard_club_results,
#dashboard_exercise_container,
#dashboard_injury_overview_container {
  min-height: 320pt;
}

.dashboard-card .graph_container {
  position: relative;
  max-width: 80vw;
}

#dashboard_exercise_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

#dashboard_injury_overview_container .grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: 1fr; */
  grid-column-gap: 1%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.grid-container > *:nth-child(2n-1):nth-last-of-type(1) {
  grid-column: span 2;
}

.dashboard-exercise {
  height: 100%;
  min-width: 100%;
  padding: 1%;
  scroll-snap-align: start;
  overflow: auto;
}

@media (max-width: 640px) {
  .dashboard-user-results {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .dashboard-user-result-photo-container {
    max-width: 50pt;
  }

  .dashboard-user-results h5 {
    font-size: 1rem;
  }
  .dashboard-user-results h4 {
    font-size: 1.3rem;
  }

  .dashboard-content-container {
    margin-top: 28pt;
  }

  .dashboard-content-container.no-margin-top {
    margin-top: 0;
  }

  .dashboard-card .graph_container {
    position: relative;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1280px) {
  #dashboard_injury_overview_container .grid-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
