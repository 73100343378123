.payment_result_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.payment_result_text_container {
  padding: 10pt;
  border-radius: 10pt;
}
