.label-container {
  display: flex;
  align-items: center;
}

.login-outer-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.login-form-container {
  padding: 10pt;
  width: 480pt;
  border-radius: 15pt;
  height: auto;
  background-color: white;
  z-index: 2;
}

.account-activation-container {
  padding: 10pt;
  width: 480pt;
  border-radius: 15pt;
  height: auto;
  background-color: white;
  z-index: 2;
}

.reset-password-container {
  padding: 10pt;
  width: 480pt;
  border-radius: 15pt;
  height: auto;
  background-color: white;
  z-index: 2;
}

.btn {
  min-height: 36pt !important;
  border-radius: 5pt !important;
  font-family: Clio !important;
}

.login-button,
.reset-password-button,
.account-activation-button {
  font-weight: bold !important;
  font-size: 14pt !important;
}

.width-100 {
  width: 100%;
}

.min-width-100 {
  min-width: 100%;
}

.width-auto {
  width: auto;
}

.login-image {
  width: 80%;
  margin: 5% auto 5% auto;
}

@media (max-width: 640px) {
  .login-form-container,
  .account-activation-container,
  .reset-password-container {
    width: 90vw;
  }
}

.login-background-circle-bigger {
  width: 180pt;
  height: 180pt;
  border-radius: 50%;
  background-color: white;
  opacity: 0.2;
  position: absolute;
  top: 15%;
  left: 22%;
}

.login-background-circle-smaller {
  width: 130pt;
  height: 130pt;
  border-radius: 50%;
  background-color: white;
  opacity: 0.2;
  position: absolute;
  top: 75%;
  left: 60%;
}

.login-background-circle-empty {
  width: 220pt;
  height: 220pt;
  border-radius: 50%;
  border-color: rgba(255, 255, 255, 0.8);
  border-width: 2px;
  border-style: solid;
  background-color: transparent;
  position: absolute;
  top: 7%;
  left: 24%;
}

.login-language-selector {
  position: absolute;
  top: 10pt;
  right: 10pt;
}
