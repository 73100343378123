.popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content{
    position: relative;
    background: white;
    border-radius: 20px;
    width: fit-content;
    height: fit-content;
    padding: 0 20px 20px 20px;
    animation: popup 0.2s;
}

.close-btn{
    outline: none;
    border: none;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-size: 30px;
    line-height: 30px;
    color: var(--primary-color);
}

.popup-title{
    font-size: 40px;
    font-weight: normal;
    padding: 0 20px;
    color: var(--primary-color);
}

@keyframes popup {
    0%{
        transform: scale(.7);
    }
    100%{
        transform: scale(1);
    }
}